import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { sharedRoutes } from "./sharedRoutes";

const CustomerSearchPage = lazy(() => import("pages/CustomerSearchPage"));
const CustomerPage = lazy(() => import("pages/CustomerPage"));
const ProfilePage = lazy(() => import("pages/ProfilePage"));

export const privateRoutes = [
    {
        key: "root",
        element: <Outlet />,
        children: [
            {
                key: "customerSearch",
                path: "/",
                index: true,
                inMenu: false,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <CustomerSearchPage />
                    </Suspense>
                ),
                children: []
            },
            {
                key: "customerDetail",
                path: "/customer/:customerId",
                inMenu: false,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <CustomerPage />
                    </Suspense>
                ),
                children: []
            },
            {
                key: "profile",
                path: "profile",
                inMenu: false,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <ProfilePage />
                    </Suspense>
                ),
                children: []
            },
            ...sharedRoutes
        ]
    }
];
